<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ purchasesPayment.name }}</span>
    </div>

    <div>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.DATE") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(purchasesPayment.date) }}
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.INVOICE") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesPayment.invoice" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.CAFETERIA") }}</dt>
        <dd class="col-sm-8">
          <cafeteria :cafeteria="purchasesPayment.cafeteria" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
        <dd class="col-sm-8">
          <establishment :establishment="purchasesPayment.establishment" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
        <dd class="col-sm-8">
          <service-center :serviceCenter="purchasesPayment.serviceCenter" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="purchasesPayment.allowedLocations" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="purchasesPayment.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <purchasesPayment-status-badge :purchasesPayment="purchasesPayment" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.AMOUNT") }}</dt>
        <dd class="col-sm-8">
          {{ $formatCurrency(purchasesPayment.amount) }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("PURCHASES_PAYMENTS.SOURCE") }}</dt>
        <dd class="col-sm-8">
          {{
            $t(
              `PURCHASES_PAYMENTS.PURCHASES_PAYMENT_SOURCE_${purchasesPayment.source}`
            )
          }}
        </dd>
      </dl>

      <dl class="row" v-if="purchasesPayment.excerpt">
        <dt class="col-sm-4">
          {{ $t("COMMON.EXCERPT") }}
        </dt>
        <dd class="col-sm-8">
          <div v-html="purchasesPayment.excerpt"></div>
        </dd>
      </dl>

      <dl class="row" v-if="purchasesPayment.transaction_id">
        <dt class="col-sm-4">
          {{ $t("PURCHASES_PAYMENTS.TRANSACTION_ID") }}
        </dt>
        <dd class="col-sm-8">
          {{ purchasesPayment.transaction_id }}
        </dd>
      </dl>

      <dl class="row" v-if="purchasesPayment.transaction_data">
        <dt class="col-sm-4">
          {{ $t("PURCHASES_PAYMENTS.TRANSACTION_DATA") }}
        </dt>
        <dd class="col-sm-8">
          {{ purchasesPayment.transaction_data }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="purchasesPayment.created_at">
          {{ $timeZoneDateFormat(purchasesPayment.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="purchasesPayment.updated_at">
          {{ $timeZoneDateFormat(purchasesPayment.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import Cafeteria from "@/components/Cafeteria.vue";
import Establishment from "@/components/Establishment.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import PurchasesPaymentStatusBadge from "./PurchasesPaymentStatusBadge.vue";

export default {
  name: "purchasesPayment-view-global",

  components: {
    PurchasesPaymentStatusBadge,
    Cafeteria,
    Establishment,
    ServiceCenter,
  },

  props: ["purchasesPayment"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    purchasesPayment(purchasesPayment) {},
  },
};
</script>
